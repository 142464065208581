import React, { useEffect, useState } from 'react'
import { CarModel } from '../Services/apiServices';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../utlis/Loader';
import { motion, useAnimation } from "framer-motion";
import Breadcrumb from '../utlis/Breadcrumb';



const CarModelList = () => {
    const { models } = useParams();
    const [Model, setModel] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const controls = useAnimation();
    const [animationStarted, setAnimationStarted] = useState(false);

    useEffect(() => {
        if (!animationStarted) {
            controls.start({ opacity: 1, y: 0 });
            setAnimationStarted(false);
        }
    }, [controls, animationStarted]);

    const handleInView = (inView) => {
        if (inView && !animationStarted) {
            controls.start({ opacity: 1, y: 0 });
            setAnimationStarted(true);
        }
    };

    useEffect(() => {
        CarModel(models).then((res) => {
            if (res?.success) {
                setModel(res?.data)
                setLoader(false)
            } else {
                navigate('/404')
            }
        })
    }, [])

    const breadcrumbs = [
        { label: "Home", url: "/" },
        {
            label: Model?.company?.name,
            url: "",
        },
    ];


    return (
        <>
            {
                loader ?
                    <Loader /> :
                    <div className="container mt-4">
                        <Breadcrumb items={breadcrumbs} />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div>
                                <img src={Model?.company?.img_name} style={{ width: "100px" }} alt="" srcset="" />
                            </div>
                            {/* <div>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{Model?.company?.name}</span>
                            </div> */}
                        </div>
                        <div className='row'>
                            {
                                Model?.data?.map((e, index) => {
                                    return (
                                        <motion.div
                                            key={index}
                                            className="col-lg-2 col-md-3 col-sm-6 col-12"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={controls}
                                            transition={{ duration: 0.5 }}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <motion.div
                                                className="pro_card"
                                                onViewportEnter={(viewportEntry) =>
                                                    handleInView(viewportEntry.target)
                                                }
                                            >

                                                <a href={`/${models}/${e?.id}`}>
                                                    <div className="product-card" >
                                                        <div className="product-tumb">
                                                            <img src={e?.img_name} alt="" />
                                                        </div>
                                                        <hr />
                                                        <div className="product-details">
                                                            <a href={`/${models}/${e?.id}`}>{e?.name}</a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </motion.div>
                                        </motion.div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default CarModelList